<template>
  <section class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Blog - Upravit <span class="utils__link--underlined text-primary">#{{ this.id }}</span></strong>
      </div>
      <action-tools :router-options="routerOptions"></action-tools>
    </div>
    <div class="card-body">
      <div class="table-operations">
        <language-tab></language-tab>
      </div>
      <a-form :form="form" @submit="handleSubmit">
        <a-tabs @change="key => onTabChange(key, 'tabActiveKey')" :activeKey="tabActiveKey">
          <a-tab-pane key="main">
            <a-badge dot slot="tab">
              Hlavní údaje
            </a-badge>
            <h4 class="text-black mt-4 mb-3"><strong>Nastavitelné podle jazyka</strong></h4>
            <a-form-item
              label="Nadpis"
              :validate-status="error('ble_name') ? 'error' : ''"
              :help="error('ble_name') || ''"
            >
              <a-input placeholder="Nadpis" v-decorator="['ble_name', {rules: [{max: 100, message: 'Nadpis nemůže být delší než 100 znaků!'}, {required: true, message: 'Nadpis musí být vyplněn!'}]}]"/>
            </a-form-item>

            <a-form-item
              label="Obsah"
              :validate-status="error('ble_content') ? 'error' : ''"
              :help="error('ble_content') || ''"
            >
              <ckeditor @ready="initCKEditor" :editor="editor" :config="editorConfig" class="editor" v-model="editorContent"></ckeditor>
              <a-input class="d-none" v-decorator="['ble_content', {rules: [{required: true, message: 'Obsah musí být vyplněn!'}]}]"></a-input>
            </a-form-item>

            <a-form-item label="Zveřejnit?">
              <a-checkbox v-decorator="['ble_is_active', { valuePropName: 'checked', initialValue: true }]"/>
            </a-form-item>

            <h4 class="text-black mt-4 mb-3"><strong>Ostatní</strong></h4>

            <a-form-item
              label="Vyber kategorii"
              :validate-status="error('bcy_id') ? 'error' : ''"
              :help="error('bcy_id') || ''"
            >
              <a-select placeholder="-- Vyber kategorii --" :loading="item.bcy_id.loading" :options="blogCategories.map(x => { return { value: x.bcy_id, label: x.languages.length > 0 ? '# ' + x.bcy_id + ' | ' + x.languages[0].pivot.bce_name : '# ' + x.bcy_id + ' | není nastaveno' } })" v-decorator="['bcy_id', {rules: [{required: true, message: 'Kategorie musí být vyplněn!'}]}]"></a-select>
            </a-form-item>

            <a-form-item
              label="Vyber tagy"
              :validate-status="error('tags') ? 'error' : ''"
              :help="error('tags') || ''"
            >
              <a-select placeholder="-- Vyber tagy --" :loading="item.tags.loading" mode="tags" :options="tags.map(x => { return { value: x.tag_id.toString(), label: x.blog_tag_languages.length > 0 ? '# ' + x.tag_id + ' | ' + x.blog_tag_languages[0].pivot.bte_name : '# ' + x.tag_id + ' | není nastaveno' } })" v-decorator="['tags', {rules: [{required: true, message: 'Tagy musí být vyplněn!'}]}]"></a-select>
            </a-form-item>

            <my-form-image-item
              title="Obrázek"
              subject="Blog"
              decorator-name="existing"
              :image="thumbImage"
              :image-next-count="thumbImageNextCount"
              :images="thumbImages"
              :on-image-switch-change="onThumbImageSwitchChange"
              :on-next-images-click="onNextThumbImagesClick"
              :form="form"
              :required="true"
            ></my-form-image-item>

          </a-tab-pane>
          <a-tab-pane key="comments">
            <a-badge slot="tab" :count="detail.comments_count" class="ant-badge-count-small" :offset="[0, -4]" :numberStyle="{backgroundColor: '#52c41a'}">
              <a-icon type="message"></a-icon>
              Komentáře
            </a-badge>
            <list-tree
              :activeCommentId="Number.parseInt(commentId)"
              :tree-data="currentDetailLang.comments"
              :pagination="pagination"
              :delete-comment="deleteComment"
              :click-show-modal="clickShowModal"
              :reply-to-comment="replyToComment"
              :loading="loading"
              :load-children-replies="loadChildrenReplies"
              :update-comment="updateComment"
              :quoteParent="false"
              :is-user-banned="isUserBanned"
            ></list-tree>
          </a-tab-pane>
        </a-tabs>
        <div class="form-actions">
          <a-button html-type="submit" type="primary" :disabled="loading || hasErrors(form.getFieldsError()) || !this.isThumbImageSelected(false)">Upravit</a-button>
        </div>
      </a-form>
    </div>
    <a-modal title="Zabanovat na X dní" v-model="visible" @ok="banUser(visibleId, daysBanTo, visibleUser)">
      <template slot="footer">
        <a-button key="back" @click="() => this.visible = false">Storno</a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="banUser(visibleId, daysBanTo, visibleUser)">
          Ok
        </a-button>
      </template>
      <a-input-number placeholder="Zabanovat na X dní" :min="1" :defaultValue="1" v-model="daysBanTo"></a-input-number>
    </a-modal>
  </section>
</template>

<script>
import LanguageTab from '@/components/MyComponents/LanguageTab'
import editorMixin from '@/services/editorMixin'
import ActionTools from '@/components/MyComponents/ActionTools'
import ListTree from '@/components/MyComponents/Comments/ListTree'
import { hasErrors } from '../../../services/forms'
import imageSizeMixin from '@/components/MyComponents/Gallery/image-size-mixin'
import myFormImageItem from '@/components/MyComponents/Gallery/my-form-image-item'
import thumbImageMixin from '@/components/MyComponents/Gallery/thumb-image-mixin'

function typeOfNaN(x) {
  return Number.isNaN(x) || isNaN(x)
}

export default {
  components: {
    ActionTools, LanguageTab, ListTree, myFormImageItem,
  },
  beforeCreate() {
    this.form = this.$form.createForm(this)
  },
  mixins: [imageSizeMixin, thumbImageMixin, editorMixin],
  data() {
    return {
      hasErrors,
      daysBanTo: 1,
      visible: null,
      visibleId: null,
      visibleUser: null,
      tabActiveKey: this.initTabActiveKey(),
      tabActiveKeys: [this.initTabActiveKey()],
      commentId: this.$route.params.commentId,
      id: this.$route.params.id,
      loading: false,
      pagination: {
        pageSize: 10,
      },
      routerOptions: [
        {
          icon: 'bars',
          theme: 'outlined',
          to: '/blog',
          title: 'Seznam',
        },
      ],
      item: {
        iae_id: null,
        tags: {
          loading: false,
          active: [],
        },
        bcy_id: {
          loading: false,
          active: undefined,
        },
        languages: [],
      },
      loaded: [],
      dependencies: ['tags', 'bcy_id'],
    }
  },
  computed: {
    user: function () {
      return this.$store.getters['auth/getItem']
    },
    detail: function() {
      return this.$store.getters['blog/getDetail']
    },
    language: function () {
      return this.$store.getters['language/active']
    },
    tags: function () {
      return this.$store.getters['blogTag/currentLanguage']('')
    },
    blogCategories: function () {
      return this.$store.getters['blogCategory/currentLanguage']('')
    },
    currentDetailLang: function () {
      return this.$store.getters['blog/currentLanguageDetail']
    },
  },
  methods: {
    isUserBanned(node) {
      return node.user && node.user.banned_to !== null && node.user.uer_type !== 'AMR'
    },
    clickShowModal(user) {
      this.visible = true
      this.visibleId = user.uer_id
      this.visibleUser = user
    },
    banUser(userId, daysBanTo, userModel) {
      this.loading = true
      this.$store.dispatch('userRegistered/delete', { days: daysBanTo, items: [userId], models: [userModel] })
        .catch(() => {})
        .finally(() => {
          this.loading = false
          this.visible = false
        })
    },
    loadChildrenReplies(comment) {
      this.loading = true
      return new Promise((resolve, reject) => {
        this.$store.dispatch('comment/getByParent', comment.cmt_id)
          .then(() => {
            this.$store.commit('product/SET_LOADED_CHILDREN_REPLIES', { item: comment })
            resolve()
          })
          .catch(error => {
            reject(error.errorBag.message)
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
    updateComment(comment, message) {
      this.loading = true
      return new Promise((resolve, reject) => {
        this.$store.dispatch('comment/put', { id: comment.cmt_id, item: { cmt_message: message } })
          .then(() => {
            this.$store.commit('product/UPDATE_COMMENT_MESSAGE', { item: comment, message: message })
            resolve()
          })
          .catch(error => {
            reject(error.errorBag.message)
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
    deleteComment(comment) {
      this.loading = true
      return new Promise((resolve, reject) => {
        this.$store.dispatch('comment/delete', [comment.cmt_id])
          .then(() => {
            this.$store.commit('product/DELETE_COMMENT', comment)
            resolve()
          })
          .catch(error => {
            reject(error.message)
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
    replyToComment(comment, message) {
      this.loading = true
      const newComment = {
        cmt_message: message,
        cmt_cmt_id: comment.cmt_id,
        put_id: null,
        blg_id: this.id,
        lge_id: comment.lge_id,
        root_id: comment.root_id === null ? comment.cmt_id : comment.root_id,
        uer_id: this.user.uer_id,
      }
      return new Promise((resolve, reject) => {
        this.$store.dispatch('comment/post', newComment)
          .then((response) => {
            comment.children_count += 1
            this.$store.state.blog.detail.comments_count += 1
            const now = this.$moment()
            newComment.created_at = now
            newComment.updated_at = now
            newComment.user = this.user
            newComment.cmt_id = response.data.cmt_id
            if (comment.children) {
              comment.children.unshift(newComment)
            } else {
              comment.children = [newComment]
            }
            resolve()
          })
          .catch(error => {
            reject(error.errorBag.message)
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
    onTabChange(key, type) {
      this[type] = key
    },
    initDetail() {
      this.loading = true
      this.$store.dispatch('blog/getOne', this.id)
        .then(() => {
          const oldItem = this.item
          this.item = Object.assign({}, this.detail)
          this.item.bcy_id = {
            loading: false,
            active: oldItem.bcy_id.active !== undefined ? oldItem.bcy_id.active : this.detail.bcy_id,
          }
          this.item.iae_id = oldItem.iae_id
          this.item.tags = {
            loading: false,
            active: oldItem.tags.active,
          }
          this.item.tags.active.push({
            lge_id: this.language,
            items: [],
          })
          this.thumbImage.selectedOne = this.item.image
          if (this.tabActiveKeys.includes('main')) {
            this.initMain()
          }

          this.editorContent = this.currentDetailLang.languages.ble_content
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    initData() {
      this.loading = true
      this.dependencies.forEach((value) => {
        this.item[value].loading = true
      })
      Promise.all([
        this.$store.dispatch('blogTag/getList'),
        this.$store.dispatch('blogCategory/getList'),
      ])
        .catch(() => {})
        .then(() => {
          this.initDetail()
        })
        .finally(() => {
          this.dependencies.forEach((value) => {
            this.item[value].loading = false
          })
          this.loaded.push(this.language)
          this.loading = false
        })
    },
    handleSubmit(e) {
      e.preventDefault()
      this.handleChange(this.language)
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$store.dispatch('blog/put', { id: this.id, item: this.getData() })
            .catch(() => {})
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    getData() {
      const data = new FormData()
      const tags = []
      if (this.form.getFieldValue('tags') !== undefined) {
        this.form.getFieldValue('tags').forEach(x => {
          if (!typeOfNaN(x) && this.tags.find(y => y.tag_id === parseInt(x))) {
            tags.push(parseInt(x))
          }
        })
      }
      const jsonObject = {
        bcy_id: this.form.getFieldValue('bcy_id'),
        languages: this.item.languages.filter(x => x.ble_name !== undefined && x.ble_name.trim().length > 0 && x.ble_content !== undefined && x.ble_content.trim().length > 0),
        tags: {
          new: {
            count: this.item.tags.active[0].items.length,
            languages: this.item.tags.active[0].items.length === 0 ? [] : this.item.tags.active,
          },
          existing: tags,
        },
        iae_id: this.thumbImage.selectedOne.iae_id,
      }
      data.append('json', JSON.stringify(jsonObject))
      data.append('_method', 'PUT')
      return data
    },
    handleChange(oldLanguage) {
      const foundObj = { blog: false, tag: false }
      const newObj = {
        blog: {
          lge_id: oldLanguage,
          ble_content: this.form.getFieldValue('ble_content'),
          ble_name: this.form.getFieldValue('ble_name'),
          ble_is_active: this.form.getFieldValue('ble_is_active'),
        },
        tag: {
          lge_id: oldLanguage,
          items: this.form.getFieldValue('tags') === undefined ? [] : this.form.getFieldValue('tags').filter(x => {
            return !(!typeOfNaN(x) && this.tags.find(y => y.tag_id === parseInt(x)))
          }),
        },
      }
      this.item.languages.some((value, key) => {
        if (value.lge_id === oldLanguage) {
          this.item.languages[key] = newObj.blog
          foundObj.blog = true
          return true
        }
      })
      this.item.tags.active.some((value, key) => {
        if (value.lge_id === oldLanguage) {
          this.item.tags.active[key] = newObj.tag
          foundObj.tag = true
          return true
        }
      })
      if (!foundObj.blog) {
        this.item.languages.push(newObj.blog)
      }
      if (!foundObj.tag) {
        this.item.tags.active.push(newObj.tag)
      }
    },
    error(fieldName) {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched(fieldName) && getFieldError(fieldName)
    },
    refreshComponent(id) {
      this.id = id
      this.refreshThumbImage()
      this.item = {
        iae_id: null,
        tags: {
          loading: false,
          active: [],
        },
        bcy_id: {
          loading: false,
          active: undefined,
        },
        languages: [],
      }
      this.loaded = []
    },
    initTabActiveKey() {
      if (this.$route.params.commentId) {
        return 'comments'
      }
      return 'main'
    },
    initMain() {
      this.form.getFieldDecorator('bcy_id')
      this.form.getFieldDecorator('ble_name')
      this.form.getFieldDecorator('ble_content')
      this.form.getFieldDecorator('ble_is_active')
      this.form.getFieldDecorator('tags')
      this.form.getFieldDecorator('existing')
      this.form.setFieldsValue({
        bcy_id: this.item.bcy_id.active,
        ble_is_active: this.currentDetailLang.languages.ble_is_active,
        ble_content: this.currentDetailLang.languages.ble_content,
        ble_name: this.currentDetailLang.languages.ble_name,
        tags: this.detail.tags,
        existing: this.item.iae_id !== null ? this.item.iae_id : undefined,
      })
    },
  },
  created() {
    if (this.language !== null && !this.loaded.includes(this.language)) {
      this.initData()
    }
  },
  watch: {
    editorContent(newValue) {
      this.$nextTick(() => {
        this.form.setFieldsValue({
          ble_content: newValue,
        })
      })
    },
    tabActiveKey(newValue) {
      if (!this.tabActiveKeys.includes(newValue)) {
        if (newValue === 'main') {
          this.initMain()
        }
        this.tabActiveKeys.push(newValue)
      }
    },
    language(newValue, oldValue) {
      if (oldValue !== null && this.loaded.includes(oldValue)) {
        this.handleChange(oldValue)
      }
      if (newValue !== null) {
        if (!this.loaded.includes(this.language)) {
          this.initData()
        } else {
          const foundObj = {
            blog: this.item.languages.find(x => x.lge_id === newValue),
            tags: this.item.tags.active.find(x => x.lge_id === newValue),
          }
          let newObj
          if (foundObj.blog === undefined) {
            newObj = {
              ble_is_active: true,
              ble_name: undefined,
              ble_content: undefined,
            }
            this.editorContent = ''
          } else {
            newObj = {
              ble_is_active: foundObj.blog.ble_is_active,
              ble_name: foundObj.blog.ble_name,
              ble_content: foundObj.blog.ble_content,
            }
            this.editorContent = foundObj.blog.ble_content
          }
          newObj.tags = this.form.getFieldValue('tags') === undefined ? [] : this.form.getFieldValue('tags').filter(x => {
            return !typeOfNaN(x) && this.tags.find(y => y.tag_id === parseInt(x))
          })
          if (foundObj.tags !== undefined) {
            newObj.tags = newObj.tags.concat(foundObj.tags.items)
          }
          newObj.tags = newObj.tags.filter((item, index) => newObj.tags.indexOf(item) === index)
          this.form.setFieldsValue(newObj)
          if (!this.loaded.includes(this.language)) {
            this.initData()
          }
        }
        this.form.validateFields()
      }
    },
    '$route.params.id'(newId, oldId) {
      this.refreshComponent(newId)
      if (this.language !== null) {
        this.initData()
      }
    },
    '$route.params.commentId'(newId, oldId) {
      if (newId) {
        this.commentId = newId
        this.tabActiveKey = 'comments'
      }
    },
  },
}
</script>

<style lang="scss" module>
  @import "./style.module.scss";
</style>
